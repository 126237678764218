//
//
//
//
//
//
//
//

export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      "default": '' },

    img: {
      type: String,
      "default": '' },

    title: {
      type: String,
      "default": '' },

    opened: {
      type: Boolean,
      "default": true } },


  render: function render(h, context) {var _context$props =
    context.props,icon = _context$props.icon,title = _context$props.title,img = _context$props.img,opened = _context$props.opened;
    var vnodes = [];
    var imgStyle = opened ? '' : 'margin-left:18px';

    if (icon) {
      // vnodes.push(<svg-icon icon-class={icon}/>)
      vnodes.push(h("i", { "class": icon }));
    }

    if (img) {
      vnodes.push(h("img", { "attrs": { "width":

          "18", "height":
          "18", "src":

          img }, "style":
        "margin-right:10px" }));


    }

    if (title) {
      vnodes.push(h("span", { "slot": "title" }, [title]));
    }
    return vnodes;
  } };